import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { NoMatch } from './components/nomatch';
import Translate from './translate/translate';

export function AppRoutes() {

  return (
    <Routes>
      <Route index element={<Translate />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};


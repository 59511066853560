import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontSize: 12,
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 16,
    },
    body1: {
      fontSize: 12,
      fontWeight: 500,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
    },
    h1: {
      fontSize: 44,
    },
    h2: {
      fontSize: 38,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700
    },
    h4: {
      fontSize: 22,
      fontWeight: 700
    },
    h5: {
      fontSize: 18,
      fontWeight: 500
    },
    h6: {
      fontSize: 16,
      fontWeight: 500
    },
  },
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    primary: {
      main: '#F37436',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  },
  components: {
    MuiButton: {

      styleOverrides: {
        root: {
          fontSize: '14px',
          textTransform:'capitalize'
        },
      }
      
    },
   MuiOutlinedInput:{
    styleOverrides:{
      root:{
        fontSize:'24px'
      }
    }
   } ,
  
  }
})

export default theme
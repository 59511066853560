import { AppConstant } from './app_constant';

export class AppBaseError {
  code: string;
  description: string;

  constructor(code: string, description: string){
    this.code = code;
    this.description = description;
  }

  static  invalidSession: AppBaseError = new AppBaseError('403', AppConstant.invalidToken);

  static loginFailed: AppBaseError = new AppBaseError('403', AppConstant.loginError);

  static unknown = (code: string, description: string) => {
    return new AppBaseError(code, description);
  } 
}

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TranslateProvider from "../../providers/translate/translate";
import { ComputeRequestBody, PipelineResponseConfig, PipelineResponseItem, TranslateResponse } from "../../models/translateModel";

export interface TranslateState {
    isLoading: boolean,
    isSaving: boolean,
    pipelineResponse:PipelineResponseItem[],
    serviceId:string,
}

const initialState: TranslateState = {
    isLoading: false,
    isSaving: false,
    pipelineResponse:[],
    serviceId:''
}

export const translateSlice = createSlice({
    name: 'translateState',
    initialState,
    reducers: {
        loadingStart: (state, action: PayloadAction<void>) => {
            state.isLoading = true;
        },
        loadingEnd: (state, action: PayloadAction<void>) => {
            state.isLoading = false;
        },
        savingStart: (state, action: PayloadAction<void>) => {
            state.isSaving = true;
        },
        savingEnd: (state, action: PayloadAction<void>) => {
            state.isSaving = false;
        },
        setServiceID: (state, action: PayloadAction<{ serviceId: string}>) => {
            const { serviceId } = action.payload;
            state.serviceId = serviceId;
            
        }

    },

    extraReducers: (builder) => {
        builder.addCase(getNMTComputeAction.fulfilled, (state, action) => {
            state.pipelineResponse = action.payload.pipelineResponse
        });
    }
});

export const getNMTComputeAction = createAsyncThunk(
    'translateState/getNMTComputeAction',
    async (computeRequestBody: ComputeRequestBody, thunkAPI) => {
        try {
            thunkAPI.dispatch(loadingStart());
            const response: TranslateResponse = await TranslateProvider.getNMTCompute(computeRequestBody);
            thunkAPI.dispatch(loadingEnd());
            return response;
        } catch (error) {
            thunkAPI.dispatch(loadingEnd());
            console.error(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const { loadingStart, loadingEnd, savingStart, savingEnd,setServiceID } = translateSlice.actions;

export default translateSlice.reducer;
import Axios from "axios";
import Cache from "../utils/common/cache";
import { AppBaseError } from "../utils/common/app_errors";
import { AppConstant } from '../utils/common/app_constant';


const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

instance.defaults.headers.common['Authorization'] = `${Cache.getToken()}`;


export async function get(url: string) {
  try {
    const response = await instance.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data
  } catch (error) {
    throw (errorHandler(error));
  }
}


export async function postWith200Check(url: string, data: any,headers?: Record<any,any>) {
  try {
    const response = await instance.post(url, data, {
      headers: { 'Content-Type': 'application/json', ...headers }
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw AppBaseError.unknown(response.data['statusCode'], response.data['statusMessage'] ?? AppConstant.httpError);
    }
  } catch (error) {
    throw (errorHandler(error));
  }
}
export async function post(url: string, data: any) {
  try {
    const response = await instance.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data
  } catch (error) {
    throw (errorHandler(error));
  }
}
export async function postCancellable(url: string, data: any, ctrl: AbortController) {
  try {
    const response = await instance.post(url, data, {
      signal: ctrl.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data
  } catch (error) {
    throw (errorHandler(error));
  }
}
export async function postForm(url: string, data: FormData) {
  try {
    const response = await instance.postForm(url, data);

    return response.data
  } catch (error) {
    throw (errorHandler(error));
  }
}

export async function upload(url: string, data: any, onUpload: Function) {
  try {
    const response = await instance.post(url, data, {

      onUploadProgress: function (e: any) {
        console.log((e?.progress * 100).toFixed(2));
        onUpload(e);
      },

      /*{
        loaded: number;
        total?: number;
        progress?: number;
        bytes: number; 
        estimated?: number;
        rate?: number; // download speed in bytes
        download: true; // download sign
      }*/
    });
    return response.data
  } catch (error) {
    throw (errorHandler(error));
  }
}

function errorHandler(error: any) {
  return error?.description ?? (error?.message ?? AppConstant.httpError);
}
import { post, postWith200Check,  } from "../base_api_provider";
import { UrlProvider } from "../url_provider";

class SessionProvider {
  static getModelsPipeline = (withoutConfigParameters:any) => {
   
    const headers = {
      "userID":  process.env.REACT_APP_BHASHIN_USER_ID,
      "ulcaApiKey": process.env.REACT_APP_BHASHINI_ULKA_API_KEY,
    };

    return postWith200Check(UrlProvider.getModelsPipeline,withoutConfigParameters,headers);
  };
 
}

export default SessionProvider;

import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import PlayIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseIcon from '@mui/icons-material/PauseCircleOutlined';
import { Tooltip } from '@mui/material';

interface PixAudioPlayerProps {
  audio: string
}

const PixAudioPlayer = ({ audio }: PixAudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioToggle = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);

    const audioElement = audioRef.current;

    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
    }
  };

  useEffect(() => {
    handleAudioToggle();
    console.log("Audio useEffect...")
    const audioRefElement = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
    };
    if (audioRefElement) {
      audioRefElement.onended = handleEnded;
    }
    return () => {
      if (audioRefElement) {
        audioRefElement.onended = null;
      }
    };
  }, [audioRef]);

  return (
    <>
      <audio ref={audioRef} controls={false}>
        <source src={audio} />
      </audio>
      <Tooltip title= {!isPlaying ? "Play":"Pause"}>
        <Button size='large' color={'secondary'} onClick={handleAudioToggle}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Button>
      </Tooltip>
    </>
  );
};

export default PixAudioPlayer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionProvider from '../../providers/session/session';
import { PipelineResponseConfig, PipelineResponseModel } from '../../models/translateModel';
import Cache from "../../utils/common/cache";

export interface SessionState {
  token: string,
  isLoading: boolean,
  isSaving: boolean,
  lang: string,
  pipelineReponseConfig:PipelineResponseConfig[]



}

const initialState: SessionState = {
  token: '',
  isLoading: false,
  isSaving: false,
  lang: 'en',
  pipelineReponseConfig: [
    {
      taskType: '',
      config: []
    }
  ]

};


export const sessionSlice = createSlice({
  name: 'sessionState',
  initialState,
  reducers: {
    loadingStart: (state, action: PayloadAction<void>) => {
      state.isLoading = true;
    },
    loadingEnd: (state, action: PayloadAction<void>) => {
      state.isLoading = false;
    },
    savingStart: (state, action: PayloadAction<void>) => {
      state.isSaving = true;
    },
    savingEnd: (state, action: PayloadAction<void>) => {
      state.isSaving = false;
    },
    sessionLive: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    sessionExpired: (state, action: PayloadAction<void>) => {
      state.token = '';
    },

  },

  extraReducers: (builder) => {
    builder.addCase(getModelsPipelineAction.fulfilled, (state, action) => {
      state.pipelineReponseConfig = (action.payload ?? []) as PipelineResponseConfig[];
    });
  }
});
export const getModelsPipelineAction = createAsyncThunk(
  'sessionState/getModelsPipelineAction',
  async (withoutConfigParameters:any, thunkAPI) => {
    try {
      thunkAPI.dispatch(loadingStart());
      const response: PipelineResponseModel = await SessionProvider.getModelsPipeline(withoutConfigParameters);
      thunkAPI.dispatch(loadingEnd());
      Cache.setToken(response.pipelineInferenceAPIEndPoint.inferenceApiKey.value);
      return response.pipelineResponseConfig;
    } catch (error) {
      thunkAPI.dispatch(loadingEnd());
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const { loadingStart, loadingEnd, savingStart, savingEnd, sessionLive, sessionExpired } = sessionSlice.actions;

export default sessionSlice.reducer;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'

import sessionReducer from './session/slice';
import constantsReducer from './constants/slice';
import translateReducer from './translate/slice';

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    sessionState: sessionReducer,
    constantsState: constantsReducer,
    translateState:translateReducer,
    router: connectRouter(history),
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

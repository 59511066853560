class Cache {
  static AUTH_KEY = 'auth_token';
  static getToken = () => {
    return Cache.get(Cache.AUTH_KEY);
  }
  static setToken = (value: string) => {
    return Cache.set(Cache.AUTH_KEY, value);
  }

  static get = (key: string) => {
    return localStorage.getItem(key);
  };
  static set = (key: string, value: any) => {
    return localStorage.setItem(key, value);
  };
}

export default Cache;
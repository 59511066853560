import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toolbar } from '@mui/material'
import { ToastContainer } from 'react-toastify';
import { useAppSelector, useAppDispatch } from './store/app_hooks';
import { Header } from './ui/header';
import { AppRoutes } from './ui/routes';
import { Footer } from './ui/footer';
import { getModelsPipelineAction } from './store/session/slice';
import { constants } from './utils/constants';

function App() {

  console.log("process",process.env);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getModelsPipelineAction(constants.getPipelineWthoutConfig));
  }, [dispatch]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ToastContainer position="top-center" theme="colored" hideProgressBar pauseOnHover />
      {/* <Header /> */}
      {/* <Toolbar /> */}
      <AppRoutes />
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConstantsModel } from '../../models/app';

import ConstantsAdapter from '../../providers/constants';

export interface ConstantsState {

}

const initialState: ConstantsState = {

};

export const constantsSlice = createSlice({
  name: 'constantsState',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    
  },
});

function mapResponseToMUISelect(resp: any[]) {
  return resp.map((el: any) => {
    return {id: el.key, label: el.value};
  });
}

export default constantsSlice.reducer;

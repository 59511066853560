import { useEffect, useState } from "react"


interface UseDebounceProps {
    value: string,
  
  }

const useDebounce = (value:string ) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const id = setTimeout(() => {
            setDebouncedValue(value)
        }, 1000)

        return () => {
            clearTimeout(id)
        }
    }, [value, 1000])

    return debouncedValue
}
export default useDebounce
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { FormContainer, useForm, TextareaAutosizeElement } from 'react-hook-form-mui';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import { useAppDispatch, useAppSelector } from '../../store/app_hooks';
import { RootState } from '../../store/app_store';
import { getNMTComputeAction } from '../../store/translate/slice';
import { toast } from 'react-toastify';
import { TaskType } from '../../utils/constants';
import { ComputeRequestBody, PipelineTask } from '../../models/translateModel';
import { Box, Grid, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { PixAudioPlayer } from '../components/translate';
import { base64ToAudio } from '../../utils/file_service';
import useAudiorecorder from '../../hooks/useAudioRecorder';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { Language, StopCircle } from '@mui/icons-material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import useDebounce from '../../hooks/useDebounce';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import '../../assets/index.css';

type SourceFormValues = {
    sourceText: string;
    sourceLanguage: string;
    taskType: string;

};

type TargetFormValues = {
    targetText: string;
    targetLanguage: string;
    taskType: string;
};

interface Language {
    label: string;
    id: string;
}
let runTgtLangEffect = false;

const Translate = () => {
    const languages: Language[] = [
        { "label": 'Marathi', 'id': 'mr' },
        { 'label': 'Hindi', 'id': 'hi' },
        { "label": 'English', 'id': 'en' },
        { "label": 'संस्कृतम्', 'id': 'sa' },
        { 'label': 'ગુજરાતી', 'id': 'gu' },
        { "label": 'অসমীয়া', 'id': 'as' },
        { "label": 'bodo', 'id': 'brx' },
        { 'label': 'ಕನ್ನಡ', 'id': 'kn' },
        { "label": 'বাংলা', 'id': 'bn' },
        { "label": 'മലയാളം', 'id': 'ml' },
        { "label": 'ଓଡ଼ିଆ', 'id': 'or' },
        { "label": 'ਪੰਜਾਬੀ', 'id': 'pa' },
        { "label": 'தமிழ்', 'id': 'ta' },
        { "label": 'తెలుగు', 'id': 'te' },

    ];

    const { pipelineReponseConfig } = useAppSelector((state: RootState) => state.sessionState);
    const { pipelineResponse } = useAppSelector((state: RootState) => state.translateState);
    const dispatch = useAppDispatch();

    const sourceFormContext = useForm<SourceFormValues>({
        mode: 'onBlur',
        defaultValues: {
            sourceLanguage: 'mr',
            sourceText: '',
            taskType: ''
        },
    });

    const targetFormContext = useForm<TargetFormValues>({
        mode: 'onBlur',
        defaultValues: {
            targetLanguage: 'en',
            targetText: ''
        },
    });

    const { setValue: setSourceValue, watch: watchSource } = sourceFormContext;
    const { setValue: setTargetValue, watch: watchTarget } = targetFormContext;
    const [selectedInput, setSelectedInput] = useState('');
    const [sourceAudio, setSourceAudio] = useState('');
    const [targetAudio, setTargetAudio] = useState('');
    const [isTargetAudio, setIsTargetAudio] = useState(false);
    const [isSourceAudio, setIsSourceAudio] = useState(false);
    const [genSourceAudio, setGenSourceAudio] = useState(true);
    const sourceText$ = watchSource('sourceText');
    const sourceLanguage$ = watchSource('sourceLanguage');
    const targetText$ = watchTarget('targetText');
    const targetLanguage$ = watchTarget('targetLanguage');
    let [audioURL, strbase64, isRecording, startRecording, stopRecording] = useAudiorecorder();
    let piplineTaskList: PipelineTask[] = [];
    let piplineReqBody: ComputeRequestBody;
    const debouncedSearchValue = useDebounce(sourceText$);
    const [voice, setVoice] = React.useState('male');

    const handleVoice = (event: any, newVoice: React.SetStateAction<string> | null) => {
        if (newVoice !== null) {
            setSourceAudio('');
            setTargetAudio('');
            setVoice(newVoice);
        }
    };

    useEffect(() => {

        if (pipelineResponse?.length > 0) {

            pipelineResponse.map((item, index) => {
                console.log("UseEffect...");
                console.log(item.taskType)
                if (item.taskType === TaskType.TRANSLATION) {
                    const sourceValue = pipelineResponse[index]?.output[0]?.source || '';
                    const targetValue = pipelineResponse[index]?.output[0]?.target || '';
                    setSourceValue('sourceText', sourceValue)
                    setTargetValue('targetText', targetValue);
                }
                else if (item.taskType === TaskType.TTS) {
                    const base64Audio = pipelineResponse[index]?.audio[0]?.audioContent || '';
                    console.log(isSourceAudio, isTargetAudio)
                    genSourceAudio && isSourceAudio && setSourceAudio(base64ToAudio(base64Audio))
                    !genSourceAudio && isTargetAudio && setTargetAudio(base64ToAudio(base64Audio))
                }
            })

        }
    }, [pipelineResponse, isTargetAudio, isSourceAudio]);


    useEffect(() => {
        if (!sourceText$) {
            setSourceAudio('');
            setTargetAudio('');
            setTargetValue('targetText', '');
            setIsSourceAudio(false);
            setIsTargetAudio(false);
        }
        const handler = setTimeout(() => {
            setSourceValue('taskType', TaskType.TRANSLATION);
            if (sourceText$) {
                sourceOnSubmit(sourceFormContext.getValues());
                setSourceAudio("");
                setTargetAudio('');
            }
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [sourceText$]);

    useEffect(() => {
        if (strbase64) {
            // console.log("source..")
            const formData = watchSource();
            sourceOnSubmit(formData);
        }
    }, [strbase64])

    useEffect(() => {
        if (sourceLanguage$) {
            setSourceAudio('');
            setIsSourceAudio(false);
        }
        if (runTgtLangEffect && (sourceText$.length != 0)) {
            sourceOnSubmit({...sourceFormContext.getValues(),taskType:'translation'});
            setSourceAudio('');
            setTargetAudio('');
            setIsSourceAudio(false);
            setIsTargetAudio(false);
        }
        runTgtLangEffect = true;
    }, [targetLanguage$, sourceLanguage$])

    const showDropdown = (event: any) => {
    }

    const sourceChange = (value: string) => {
        setSourceValue('sourceLanguage', value);

    };

    const targetChange = (value: string) => {
        setTargetValue('targetLanguage', value);
    };

    const sourceOnSubmit = async (data: SourceFormValues) => {
        piplineTaskList = [];

        const fetchedServiceId = getServiceID(data.taskType, data.sourceLanguage);

        console.log("str base64..", strbase64)

        if (data.taskType === TaskType.ASR) {
            getPipelineTasks({
                taskType: data.taskType, serviceId: fetchedServiceId,
                sourceLanguage: data.sourceLanguage
            });
            const translationServiceId = getServiceID(TaskType.TRANSLATION, data.sourceLanguage)
            getPipelineTasks({
                taskType: TaskType.TRANSLATION, serviceId: translationServiceId,
                sourceLanguage: data.sourceLanguage, targetLanguage: targetLanguage$
            })

            generatePipelineRequestBody({
                pipelineTasks: piplineTaskList, audioContent: strbase64
            });
        }

        else if (data.taskType === TaskType.TRANSLATION) {
            getPipelineTasks({
                taskType: TaskType.TRANSLATION, serviceId: fetchedServiceId,
                sourceLanguage: data.sourceLanguage, targetLanguage: targetLanguage$
            })

            generatePipelineRequestBody({
                pipelineTasks: piplineTaskList, sourceText: data.sourceText
            });
        }

        else {
            setGenSourceAudio(true);
            getPipelineTasks({
                taskType: data.taskType, serviceId: fetchedServiceId,
                sourceLanguage: data.sourceLanguage
            });

            generatePipelineRequestBody({
                pipelineTasks: piplineTaskList, sourceText: data.sourceText
            });
        }




        await dispatch(getNMTComputeAction(piplineReqBody))
            .unwrap()
            .then((response: any) => {
                (data.taskType === TaskType.TTS) && setIsSourceAudio(!isSourceAudio)
            })
            .catch((error: any) => {
                toast.error(error);
            });
    };

    const targetOnSubmit = async (data: TargetFormValues) => {
        const fetchedServiceId = getServiceID(data.taskType, data.targetLanguage);
        if (data.taskType == 'tts') {
            setGenSourceAudio(false);
        }
        getPipelineTasks({
            taskType: data.taskType, serviceId: fetchedServiceId,
            sourceLanguage: data.targetLanguage
        })

        generatePipelineRequestBody({
            pipelineTasks: piplineTaskList, sourceText: data.targetText
        }
        );

        await dispatch(getNMTComputeAction(piplineReqBody))
            .unwrap()
            .then((response: any) => {
                setIsTargetAudio(!isTargetAudio)
            })
            .catch((error: any) => {
                toast.error(error);
            });

    };

    const getServiceID = (taskType: string, sourceLanguage: string) => {
        const matchingData = pipelineReponseConfig.find((data) => data.taskType === taskType);
        if (matchingData) {
            const matchingConfig = matchingData.config.find(
                (config) => config.language.sourceLanguage === sourceLanguage
            );
            if (matchingConfig) {
                return matchingConfig.serviceId;
            }
        }
        return '';
    };


    const getPipelineTasks = ({
        taskType,
        serviceId,
        sourceLanguage,
        targetLanguage,
    }: {
        taskType: string;
        serviceId: string;
        sourceLanguage: string;
        targetLanguage?: string;

    }) => {

        piplineTaskList.push({
            taskType: taskType,
            config: {
                language: {
                    sourceLanguage,
                    targetLanguage,
                },
                ...(taskType === TaskType.TTS && { gender: voice })
            },
            serviceId: serviceId
        })
    }





    const generatePipelineRequestBody = ({
        pipelineTasks,
        sourceText,
        audioContent
    }: {
        pipelineTasks: PipelineTask[]
        sourceText?: string;
        audioContent?: any
    }
    ) => {
        piplineReqBody = {
            pipelineTasks: pipelineTasks,
            inputData: {

                ...(sourceText && { input: [{ source: sourceText }] }),

                ...(audioContent && { audio: [{ audioContent: audioContent }] })

            },
        }
    };



    const recordAudio = () => {
        setSelectedInput('voice');
        setSourceValue('sourceText', "")
        setTargetValue('targetText', "");
        if (typeof startRecording === 'function') {
            startRecording();
        }
    };

    const stopAudio = () => {
        console.log("stop function...")
        setSelectedInput('text');
        if (typeof stopRecording === 'function') {
            stopRecording();
        }
    }
    const [sourceTabList, setSourceTabList] = useState<Language[]>([]);
    const [targetTabList, setTargetTabList] = useState<Language[]>([]);

    useEffect(() => {
        setSourceTabList(languages.slice(0, 3));
        setTargetTabList(languages.slice(2, 5));

        console.log(sourceTabList);
        console.log(targetTabList);
    }, []);
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openTarget = Boolean(anchorE2);

    const handleTabsLanguageChange = (newValue: string, isSourceTab: boolean) => {
        // const sourceOrTargetTabList = isSourceTab ? sourceTabList : targetTabList;
        // const sourceOrTargetLanguage$ = isSourceTab ? targetLanguage$ : sourceLanguage$;
        if(isSourceTab && targetTabList.some(lang=>lang.id==newValue) ){
            const filteredLanguages = languages.filter(language => language.id !== newValue);
            setTargetTabList((prev)=>{
                return filteredLanguages.splice(0,3)}
            );
            setTargetValue("targetLanguage", filteredLanguages[0].id)
            if(targetTabList.some(lang=>lang.id==newValue) && newValue !==targetLanguage$){
                setTargetValue('targetLanguage', targetLanguage$);
            }
            setSourceValue('sourceText', '');
        }

        // if (sourceOrTargetLanguage$ === newValue) {
        
            // const filteredLanguages = languages.filter(language => language.id !== sourceOrTargetLanguage$);
            // let index = 0;
            // let randomLanguage = filteredLanguages[index];
            // sourceOrTargetTabList.map(item => {
            //     if (item.id === randomLanguage.id) {
            //         index++;
            //         randomLanguage = filteredLanguages[index];
            //     }
            // });

            // const updatedTabs = [randomLanguage, ...sourceOrTargetTabList.filter((item, index) => index !== 0)];
            // if (isSourceTab) {
            //     setTargetValue("targetLanguage", randomLanguage.id)
            //     setTargetTabList(updatedTabs);
            // } else {
            //     setSourceValue("sourceLanguage", randomLanguage.id)
            //     setSourceTabList(updatedTabs);
            // }
            // const updatedTabs = [...sourceOrTargetTabList.filter((item, index) => index !== 0)];
            // if (isSourceTab) {
            //     setTargetValue("targetLanguage", randomLanguage.id)
            //     setTargetTabList(updatedTabs);
            // } else {
            //     setSourceValue("sourceLanguage", randomLanguage.id)
            //     setSourceTabList(updatedTabs);
            // }
        // }

    };


    const handleTabClose = (selectedItem: Language, isSourceTab: boolean) => {
        const sourceOrTargetTabList = isSourceTab ? sourceTabList : targetTabList;
        const setTabList = isSourceTab ? setSourceTabList : setTargetTabList;
        const tabIndex = sourceOrTargetTabList.findIndex(item => item.id === selectedItem.id);

        handleTabsLanguageChange(selectedItem.id, isSourceTab);


        if (tabIndex !== -1) {
            const updatedTabs = [
                sourceOrTargetTabList[tabIndex],
                ...sourceOrTargetTabList.slice(0, tabIndex),
                ...sourceOrTargetTabList.slice(tabIndex + 1)
            ];
            setTabList(updatedTabs);
        } else {
            const updatedTabs = [selectedItem, ...sourceOrTargetTabList.filter((item, index) => index !== 0)];
            setTabList(updatedTabs);
        }
    };


    const handleSourceClose = (selectedItem: Language) => {
        setAnchorEl(null);
        handleTabClose(selectedItem, true);
    };

    const handleTargetClose = (selectedItem: Language) => {
        setAnchorE2(null);
        handleTabClose(selectedItem, false);
    };

    return (
        <Box>
            <div className='logo'>
            <img src='/logo.png' alt="SixAI" />
            </div>
            <Stack direction="row" spacing={4} marginLeft={8}>
                <ToggleButtonGroup
                    value={voice}
                    exclusive
                    onChange={handleVoice}
                    aria-label="text alignment">
                    <ToggleButton value="male" aria-label="left aligned">
                        <ManIcon />
                    </ToggleButton>

                    <ToggleButton value="female" aria-label="right aligned">
                        <WomanIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Grid container columns={16} justifyContent="space-around" alignItems="center" marginTop={2} >

                <Grid item xs={12} sm={7} lg={7} >

                    <FormContainer formContext={sourceFormContext} onSuccess={sourceOnSubmit}>

                        <Stack direction='row' alignItems='center' sx={{ pl: 1 }}>
                            <h3 >Source Language</h3>
                            <Tabs
                                value={sourceLanguage$}
                                onChange={(event, newValue) => {
                                    setSourceValue('sourceLanguage', newValue);
                                    console.log(newValue);
                                    handleTabsLanguageChange(newValue, true);
                                }}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {sourceTabList.map((language) => (
                                    <Tab key={language.id} value={language.id} label={language.label} />
                                ))}
                            </Tabs>
                            <div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    size='large'
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                >
                                    <ExpandMoreIcon sx={{ height: 30, width: 30 }} />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    PaperProps={{
                                        style: {
                                            width: '100%'
                                        },
                                    }}
                                >

                                    {/* selected={option === 'Pyxis'} */}
                                    <Grid container spacing={2} >
                                        {languages.map((item) => (
                                            <Grid item key={item.id} xs={3} >
                                                <MenuItem onClick={() => { setSourceValue('sourceLanguage', item.id); handleSourceClose(item); }} sx={{ fontSize: 16, px: 6 }}>{item.label}</MenuItem>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Menu>
                            </div>

                        </Stack>


                        <Box sx={{ border: 1, borderRadius: 4, borderColor: '#DDDDDD' }}>
                            <TextareaAutosizeElement
                                aria-label="minimum height"
                                minRows={3}
                                rows={3}
                                placeholder={selectedInput === 'voice' ? "Speak Out..." : "Enter text here..."}
                                name="sourceText"
                                control={sourceFormContext.control}
                                style={{ width: '100%', padding: 6 }}
                                resizeStyle="inline"
                                onChange={showDropdown}
                            />

                            <Stack direction="row" alignItems={'center'}>
                                <Tooltip title="Translate">
                                    <Button type={'submit'} disabled={!sourceText$} onClick={() => setSourceValue('taskType', TaskType.TRANSLATION)}>
                                        <GTranslateIcon />
                                    </Button>
                                </Tooltip>
                                {selectedInput !== "voice" ? <Button size="small">
                                    <Tooltip title="Speak">
                                        <MicOutlinedIcon onClick={() => recordAudio()} />
                                    </Tooltip>
                                </Button> :
                                    <Tooltip title="Stop">
                                        <Button size="small">
                                            <StopCircle onClick={() => {
                                                setSourceValue('taskType', TaskType.ASR)
                                                stopAudio();
                                            }} />
                                        </Button></Tooltip>}
                                {(!sourceAudio && sourceText$) && (
                                    <Tooltip title="Generate Audio">
                                        <Button type={'submit'} size='large' color={'secondary'} onClick={() => setSourceValue('taskType', TaskType.TTS)}>
                                            <VolumeUpIcon></VolumeUpIcon>
                                        </Button>
                                    </Tooltip>
                                )}


                                {(sourceAudio && sourceText$) && <PixAudioPlayer audio={sourceAudio} />}



                            </Stack>
                        </Box>
                    </FormContainer>

                </Grid>
                <Grid item xs={12} sm={7} lg={7} >
                    <FormContainer formContext={targetFormContext} onSuccess={targetOnSubmit}>
                        <Stack direction='row' alignItems='center' sx={{ pl: 1 }}>
                            <h3 >Target Language</h3>
                            <Tabs
                                value={targetLanguage$}
                                onChange={(event, newValue) => {
                                    setTargetValue('targetLanguage', newValue);
                                    handleTabsLanguageChange(newValue, false);


                                }}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {targetTabList.map((language) => (
                                    <Tab key={language.id} value={language.id} label={language.label} />
                                ))}
                            </Tabs>

                            <div>
                                <IconButton
                                    aria-label="more"
                                    id="target-button"
                                    size='large'
                                    aria-controls={open ? 'target-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => setAnchorE2(event.currentTarget)}
                                >
                                    <ExpandMoreIcon sx={{ height: 30, width: 30 }} />
                                </IconButton>
                                <Menu
                                    id="target-menu"
                                    anchorEl={anchorE2}
                                    open={openTarget}
                                    onClose={() => setAnchorE2(null)}
                                    PaperProps={{
                                        style: {
                                            width: '100%'
                                        },
                                    }}
                                >
                                    <Grid container spacing={2} >
                                        {languages.filter(item=>item.id!==sourceLanguage$).map((item) => (
                                            <Grid item key={item.id} xs={3} >
                                                <MenuItem onClick={() => { console.log("target"); setTargetValue('targetLanguage', item.id); handleTargetClose(item); }} sx={{ fontSize: 16, px: 6 }}>{item.label}</MenuItem>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Menu>
                            </div>
                        </Stack>
                        <Box sx={{ border: 1, borderRadius: 4, borderColor: '#DDDDDD' }}>
                            <TextareaAutosizeElement
                                aria-label="minimum height"
                                minRows={3}
                                rows={3}
                                name="targetText"
                                placeholder="Output..."
                                resizeStyle="vertical"
                                sx={{ backgroundColor: '#f5f5f5', borderRadius: 4 }}
                                disabled
                                style={{ width: '100%', padding: 6 }}
                            />
                            <Stack direction="row" alignItems={'center'} sx={{ borderBottomLeftRadius: 20 }}>
                                {(!targetAudio && targetText$) && (
                                    <Tooltip title="Generate Audio">
                                        <Button type={'submit'} size='large' color={'secondary'} onClick={() => setTargetValue('taskType', TaskType.TTS)}>
                                            <VolumeUpIcon></VolumeUpIcon>
                                        </Button>
                                    </Tooltip>
                                )}
                                {(targetAudio && targetText$) && <PixAudioPlayer audio={targetAudio} />}
                            </Stack>
                        </Box>
                    </FormContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Translate;

export enum TaskType {
    TRANSLATION = 'translation',
    ASR = 'asr',
    TTS = 'tts',
  }
export const constants = {
    getPipelineWthoutConfig:{
        "pipelineTasks" : [
            {
                "taskType" : TaskType.ASR
            },
            {
                "taskType": TaskType.TRANSLATION
            },
            {  
                "taskType": TaskType.TTS
            }
        ],
        "pipelineRequestConfig" : {
            "pipelineId" : process.env.REACT_APP_BHASHINI_PIPELINE_ID
        }
    }
}

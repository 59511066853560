import { ComputeRequestBody } from "../../models/translateModel";
import { postWith200Check } from "../base_api_provider";
import { UrlProvider } from "../url_provider";


class TranslateProvider {
  static getNMTCompute = (computeRequestBody:ComputeRequestBody) => {
    return postWith200Check(UrlProvider.getComputeRequest, computeRequestBody);
  };
 
}

export default TranslateProvider;

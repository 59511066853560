import React, { useEffect, useState, useRef } from 'react';

const useAudiorecorder = () => {
    const [audioURL, setAudioURL] = useState<string>("");
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [wavBase64, setWavBase64] = useState<string>("");
    const audioContext = useRef<AudioContext | null>(null);
    const audioChunks = useRef<Float32Array[]>([]);

    useEffect(() => {
        const startRecording = async () => {
            try {
                audioContext.current = new (window.AudioContext)();
                const microphone = await navigator.mediaDevices.getUserMedia({ audio: true });
                const microphoneSource = audioContext.current.createMediaStreamSource(microphone);

                const scriptProcessor = audioContext.current.createScriptProcessor(4096, 1, 1);
                scriptProcessor.onaudioprocess = (event) => {
                    const audioBuffer = event.inputBuffer;
                    const audioData = audioBuffer.getChannelData(0);
                    audioChunks.current.push(new Float32Array(audioData));
                };

                microphoneSource.connect(scriptProcessor);
                scriptProcessor.connect(audioContext.current.destination);

                audioContext.current.resume();
                setIsRecording(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        };

        const stopRecording = () => {
            if (audioContext.current) {
                audioContext.current.suspend();

                const float32Array = new Float32Array(audioChunks.current.length * 4096);
                let offset = 0;

                for (const chunk of audioChunks.current) {
                    float32Array.set(chunk, offset);
                    offset += chunk.length;
                }

                const wavBlob = encodeWav(float32Array, audioContext.current.sampleRate);
                const reader = new FileReader();

                reader.readAsDataURL(wavBlob);

                reader.onloadend = function () {
                    const wavBase64 = reader.result?.toString().replace("data:audio/wav;base64,", "") || "";
                    setWavBase64(wavBase64);
                };

                setIsRecording(false);
            }
        };

        if (isRecording) {
            startRecording();
        } else {
            stopRecording();
        }

        //onunmount
        return () => {
            if (audioContext.current) {
                audioContext.current.close();
            }
        };
    }, [isRecording]);

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const getWavBase64 = () => wavBase64;

    return [audioURL, wavBase64, isRecording, startRecording, stopRecording, getWavBase64];
};

const encodeWav = (samples: Float32Array, sampleRate: number): Blob => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    writeString(view, 0, 'RIFF');

    view.setUint32(4, 36 + samples.length * 2, true);
  
    writeString(view, 8, 'WAVE');

    writeString(view, 12, 'fmt ');

    view.setUint32(16, 16, true);

    view.setUint16(20, 1, true);

    view.setUint16(22, 1, true);

    view.setUint32(24, sampleRate, true);
    
    view.setUint32(28, sampleRate * 2, true);
   
    view.setUint16(32, 2, true);

    view.setUint16(34, 16, true);
 
    writeString(view, 36, 'data');

    view.setUint32(40, samples.length * 2, true);

    floatTo16BitPCM(view, 44, samples);

    return new Blob([view], { type: 'audio/wav' });
};

const writeString = (view: DataView, offset: number, str: string): void => {
    for (let i = 0; i < str.length; i++) {
        view.setUint8(offset + i, str.charCodeAt(i));
    }
};

const floatTo16BitPCM = (output: DataView, offset: number, input: Float32Array): void => {
    for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
};

export default useAudiorecorder;
